.editarEventos{
    position: fixed;
    top: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;;
    background-color: var(--colorNegro);

    z-index: 998;
    display: block;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;


    .botonCerrar{
      position:sticky;
      top: 0px;
      padding: 0;
      height: 5rem;
      width: 5rem;
      background-color: var(--colorBlanco);
      margin: auto;

      i{
        font-size: 5rem;
        color: var(--colorNegro);
      }
    }
}