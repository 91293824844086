.general {
  min-height: 100vh;
  width: 100%;
  position: relative;

  .contenedorHeader {
    width: 100%;
    height: 10.5rem;
    
  }

  .contenedorContenido {
    width: 100%;
    padding: 0;
    margin: 0;
    padding-bottom: 10rem; /* height of your footer */
  }




  .contenedorFooter {

    width: 100%;
    position: absolute;
    bottom: 0;

  
  }
}

@media (max-width: 500px) {
  .general {
    .contenedorHeader {
       
        height: 9.1rem;
        padding: 0;
      }
    }
  }

