.respuestaPago {
  width: var(--medida100);
  background-color: var(--colorNegro);
  padding: 1rem 0;
  height: 100vh;

  .contenedor {
    margin: auto;
    padding: 1rem;
    width: 95%;
    background-color: var(--colorBlanco);
    border-radius: 50px;
    color: var(--colorNegro);
    font-size: var(--medida1_8rem);
    text-align: center;
    min-height: 95vh;
    

    .cadaParrafo {
      border-bottom: dashed var(--medida2px) var(--colorNegro);
      padding: 0.5rem 1rem;
      width: 100%;
      height: fit-content;
      text-align: left;

      .textoInput {
        font-size: 1.8rem;
        padding: 0;
        margin: 0;
      }
    }

    .contenido {
      margin: auto;
      width: 100%;
      padding: 0;

      .nota {
        font-size: 1rem;
        text-align: center;
      }

      .titulo {
        font-size: 1.6rem;
      }

      .textoResaltado {
        font-size: 1.8rem;
        font-weight: bold;
      }

      .boletos {
        font-size: 1.8rem;
        font-weight: bold;
        padding: 0;
        margin: 0;
      }

      .continuarBoton {
        width: 98%;
        height: 5rem;
        border: solid var(--colorNegro) 3px;
        border-radius: 25px;
        font-size: 1.8rem;
      }

      .p-button:hover {
        box-shadow: var(--colorNegro) -5px 10px 10px -5px;
        background: var(--colorNaranja);
        font-size: 1.8rem;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1000px) {
  .mostrarInputRifa {
    .contenedorInput {
      .formulario {
        width: 85%;
      }
    }
  }
}
