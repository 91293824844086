.mostrarBoletos {
  width: var(--medida100);
  text-align: var(--centro);
  background-color: var(--colorBlanco);
  //background-image: url("../../public/multimedia/fotosFondo/letrasMazatlan.jpg");
  padding: 1rem;
  .cadaBoleto {
    //margin: var(--cero) var(--cero) var(--medida1_5rem) var(--cero);
    display: var(--inlineFlex);
  }

  .boletosMostrados {
    display: var(--Flex);

    .contenedorBoletos {
      width: 90%;
      margin: 0.2rem;
      border: solid var(--colorNegro) 0.2px;
      border-radius: 50px;

      .letrasBoletos {
        font-size: var(--medida1_3rem);
        font-weight: bold;
        padding: var(--medida0_8rem) var(--cero);
        margin: var(--cero);
      }

      .botonDisponible {
        width: var(--medida100);
        background-color: var(--colorBlanco);
        color: var(--colorNegro);
        border-radius: 50px;
        cursor: var(--puntero);
        //box-shadow: var(--colorNegro) -2.5px 5px 5px -2.5px;

        &:not(.no-hover):hover {
          cursor: var(--puntero);
          background-color: var(--colorNegro);
          color: var(--colorBlanco);
          box-shadow: var(--colorNegro) -5px 10px 10px -5px;
        }
      }

      .botonNoDisponible {
        width: var(--medida100);
        text-decoration: line-through;
        border-radius: 50px;
        background-color: white;
        
      }

      .botonSeleccionado {
        width: var(--medida100);
        background-color: var(--colorNaranja);
        color: var(--colorNegro);
        text-decoration: underline;
        border-radius: 50px;
        box-shadow: var(--colorNegro) -5px 10px 10px -5px;

        &:not(.no-hover):hover {
          cursor: var(--puntero);
          background-color: var(--colorNegro);
          color: var(--colorBlanco);
          box-shadow: var(--colorNegro) -5px 10px 10px -5px;
          border-radius: 50px;
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .mostrarBoletos {
    padding: 1rem 0rem;
    .boletosMostrados {
      .contenedorBoletos {
        width: 90%;
        .letrasBoletos {
          font-size: var(--medida1_1rem);
        }
      }
    }
    
  }
}

