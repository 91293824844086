  .footer {
    background-color: var(--colorNegro);
    padding: 1rem;
    display: var(--flex);
    border-top: solid 4px var(--colorNaranja);

    .gridFacebook {
      grid-area: facebook;
      margin: var(--auto);
      background-color: var(--colorNegro);

      &:hover {
        border: solid white 3px;
      }
    }

    .gridAmoMazatlan {
      grid-area: amoMazatlan;
      margin: var(--auto);
    }

    .gridTikTok {
      grid-area: tiktok;
      margin: var(--auto);
      &:hover {
        border: solid white 3px;
      }
    }

    .gridWhatsApp {
      grid-area: whatsApp;
      margin: var(--auto);
      &:hover {
        border: solid white 3px;
      }
    }

    .gridColumnas {
      background-color: var(--colorNegro);
      width: var(--medida100);
      display: grid;
      grid-gap: 5px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas: "facebook amoMazatlan tiktok whatsApp";
      height: 8rem;
    }

    .logoOrganizadores {
      img {
        height: var(--medida5rem);
      }
    }

    .botonesMenu {
      width: var(--medida100);
      display: var(--inlineFlex);
      justify-content: center;
      cursor: var(--puntero);
      border: var(--cero);
      background-color: var(--colorNegro);
    }
  }

@media (max-width: 650px) {


    .footer {
      .gridColumnas {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          "amoMazatlan amoMazatlan amoMazatlan"
          "facebook tiktok whatsApp";
        height: 8rem;
      }
    }
  }


@media (max-width: 600px) {

    .footer {
      .logoOrganizadores {
        img {
          height: var(--medida4rem);
        }
      }
    }
  }


@media (max-width: 500px) {

}
