.menuPrincipalContenedor {
  .menuGeneral {
    z-index: 999;
    display: var(--flex);
    margin: 0px;
    padding: 0px;

    .listaPrincipal {
      list-style: none;
      background-color: red;
      display: var(--inlineFlex);

      &:hover > .menu1erNivel {
        display: block;
      }
    }
  
  
  
  
  
  
  
    .menu1erNivel {
      display: none;
      width: 500px;
      z-index: 1000;
      position: absolute;
      top: 7.5rem;
      padding: 0;
      margin: 0;
    
      box-shadow: var(--colorNegro) -5px 10px 10px -5px;
  
      .lista1erNivel {
        
        padding: 0;
        margin: 0;
        list-style: none;
        &:hover > .menu2doNivel {
          display: list-item;
        }
      }
    }
  
    .menu2doNivel {
      display: none;
      width: 100%;
      z-index: -1;
      background-color: transparent;
      padding: 0;
      margin: 0;
      position: absolute;
      
  
      .lista2doNivel {
        list-style: none;
        width: 250px;
        position: relative;
        right: -498px;
        top: -2rem;
        box-shadow: var(--colorNegro) -5px 10px 10px -5px;
        
      }
    }
  
    .botonesPrincipal {
      width: 150px;
      height: var(--medida5rem);
      display: var(--inlineFlex);
      align-items: var(--centro);
      justify-content: var(--centro);
      color: var(--colorNegro);
      font-size: var(--medida1_3rem);
      cursor: var(--puntero);
      background: var(--colorBlanco);
      border: var(--cero);
      border-right: var(--solido) var(--colorNegro) var(--medida3px);
      font-weight: bold;
  
      .estado {
        color: var(--colorNaranja);
      }
  
      i {
        font-size: var(--medida2rem);
      }
  
      span {
        width: max-content;
        padding: 0 var(--medida0_3rem);
      }
  
      &:hover {
        color: var(--colorNegro);
        font-weight: bolder;
        background-color: var(--colorNaranja);
        border-top: var(--contornoPrincipal);
        border-bottom: var(--contornoPrincipal);
        box-shadow: var(--colorNegro) -5px 10px 10px -5px;
        .estado {
          color: var(--colorNegro);
        }
      }
    }
  
    .botonesMenu {
      width: 100%;
      display: var(--inlineFlex);
      align-items: var(--centro);
      color: var(--colorBlanco);
      font-size: var(--medida1_3rem);
      cursor: var(--puntero);
      background: var(--colorNegro);
      border: var(--cero);
      height: 2rem;
  
      .estado {
        color: var(--colorNaranja);
      }
  
      i {
        font-size: var(--medida2rem);
      }
  
      span {
        width: max-content;
        padding: 0 var(--medida0_3rem);
      }
  
      &:hover {
        color: var(--colorNegro);
        background-color: var(--colorNaranja);
        .estado {
          color: var(--colorNegro);
        }
      }
    }
  }


  .ocultar {
    display: var(--no);
  }

  .botonHamburguesa {
    display: var(--inlineFlex);
    align-items: var(--centro);
    color: var(--colorNegro);
    height: var(--medida5rem);
    cursor: var(--puntero);
    background-color: var(--colorBlanco);
    border: 0;
    margin-left: 15px;

    i {
      font-size: var(--medida3rem);
    }
  }

  .botonMenu {
    display: var(--no);
    order: 3;
  }

  .generalMenuCel{
    display: none;
  }


}

@media (max-width: 951px) {
  .menuPrincipalContenedor {
    justify-content: space-between;


    .menuGeneral {
      display: var(--no);
    }

    .subMenu {
      position: fixed;
      top: var(--cero);
      left: var(--cero);
      width: var(--medida100);
      height: var(--medida100);
      flex-direction: column;
      background-color: var(--colorNegro);
      display: var(--no);
      overflow-y: scroll;

      &.menuActivo {
        display: block;
        z-index: 998;
      }

      .columnaMenu {
        height: var(--medida3_5rem);
        justify-content: space-between;
        display: var(--flex);
        padding: 0 0.5rem;
        align-items: var(--centro);

        h3 {
          color: var(--colorNaranja);
        }

        i {
          justify-content: var(--centro);
          align-items: var(--centro);
          height: var(--medida3rem);
          width: var(--medida3rem);
          background: var(--colorBlanco);
          font-size: 2.3rem;
          border-radius: 150px;
          color: var(--colorNegro);
        }
      }


      .columnaListado {
        padding-top: var(--medida0_3rem);
        display: var(--flex);
        display: contents;

        .generalMenuCel{
          display: block;
          z-index: 996;
          margin: 0px;
          padding: 0px;
      
          .CelListaPrincipal {
            list-style: none;
            display: block;
      
          }
        
    
          .CelMenu1erNivel {
            display: block;
            width: 100%;
            z-index: 997;
            position: relative;
            padding: 0;
            margin: 0;
    
            .CelLista1erNivel {
              
              padding: 0;
              margin: 0;
              list-style: none;
              border-bottom: dashed var(--colorBlanco) var(--medida1px);
              
            }
          }
        
          .CelMenu2doNivel {
            display: block;
            width: 100%;
            z-index: -1;
            background-color: transparent;
            padding: 0;
            margin: 0;
            position: relative;
            
        
            .CelLista2doNivel {
              list-style: none;
              position: relative;
    
              
            }
          }
        
          .CelBotonesPrincipal {
            width: 100%;
            height: var(--medida3_5rem);
            display: var(--inlineFlex);
            align-items: var(--centro);
            justify-content: var(--centro);
            color: var(--colorNegro);
            font-size: var(--medida1_8rem);
            cursor: var(--puntero);
            background: var(--colorBlanco);
            border: var(--cero);
            border-bottom: solid var(--colorNegro) var(--medida1px);
            font-weight: bold;
        
            .estado {
              color: var(--colorNaranja);
            }
        
            i {
              font-size: var(--medida2rem);
            }
        
            span {
              width: max-content;
              padding: 0 var(--medida0_3rem);
            }
        
    
          }
        
          .CelBotonesMenu {
            width: 100%;
            display: var(--inlineFlex);
            align-items: var(--centro);
            color: var(--colorBlanco);
            font-size: var(--medida1_5rem);
            cursor: var(--puntero);
            background: var(--colorNegro);
            border: var(--cero);
            height: var(--medida3rem);
            
            .estado {
              color: var(--colorNaranja);
            }
        
            i {
              font-size: var(--medida2rem);
            }
        
            span {
              width: max-content;
              padding: 0 var(--medida0_3rem);
            }
        
    
          }
    
          .CelBotones2doMenu {
            width: 100%;
            display: var(--inlineFlex);
            align-items: var(--centro);
            color: var(--colorNegro);
            font-size: var(--medida1_5rem);
            cursor: var(--puntero);
            background: var(--colorBlanco);
            border: var(--cero);
            height: var(--medida3rem);
            padding-left: 5rem;
        
            .estado {
              color: var(--colorNaranja);
            }
        
            i {
              font-size: var(--medida2rem);
            }
        
            span {
              width: max-content;
              padding: 0 var(--medida0_3rem);
            }
        
    
          }
        
      }
        
      }
    }

 
    .ocultar {
      display: var(--inlineFlex);
    }

    .botonMenu {
      display: var(--inlineFlex);
      order: 2;
    }


  }
}
