.mostrarInputRifa {
  width: var(--medida100);
  background-color: var(--colorNegro);
  background-image: url("../../../../public/multimedia/fotosFondo/letrasMazatlanNegro.jpg");
  padding-bottom: 1rem;
  
  .contenedorInput {
    width: 100%;
    font-size: var(--medida1_8rem);
    text-align: left;
    margin: var(--auto);

    .bordesuperior {
      height: 8px;
      background-color: var(--colorNaranja);
      border-top: solid var(--colorNegro) 4px;
    }

    .formulario {
      width: 50%;

      margin: auto;
      margin-top: 1rem;
      background-color: var(--colorBlanco);
      border-radius: 50px;
      color: var(--colorNegro);

      .input {
        border-bottom: dashed var(--medida2px) var(--colorNegro);
        padding: 0.5rem 1rem;
        width: 100%;
        height: fit-content;

        .textoInput {
          margin-left: 1rem;
        }

        .p-inputtext {
          width: 100%;
          border: solid var(--colorNegro) 3px;
          border-radius: 25px;
          padding-left: 2rem;
          height: 3.5rem;

          &::placeholder {
            font-size: 1.5rem;
            color: gray;
          }

          &:hover {
            background-color: var(--colorNegro);
            color: var(--colorBlanco);
            &::placeholder {
              color: var(--colorBlanco);
            }
          }
        }

        .p-inputtext.p-component.p-filled {
          /*cuando se termina de escribir*/
          color: var(--colorNegro);
          background-color: var(--colorBlanco);
          &:hover {
            color: var(--colorBlanco);
            background-color: var(--colorNegro);
          }
        }

        .p-inputtext:enabled:focus {
          background: var(--colorNegro);
          color: var(--colorBlanco);
          &::placeholder {
            color: var(--colorBlanco);
          }
        }


        .checkbox{
          
          width: 4rem;
          height: 4rem;
          padding: 0;
          margin: 0;
          display: inline-flex;
        
        }
        .p-checkbox-input {
          
          width: 3.5rem;
          height: 3.5rem;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          
    
          
          
        }
        .p-checkbox .p-checkbox-box {
          display: none;
        }
      

        label{
          
          position: relative;
          top:-1rem;
          left: 1rem;
          
          font-size: var(--medida1_8rem);
        }



        .p-togglebutton {
          position: relative;
          display: inline-flex;
          user-select: none;
          vertical-align: bottom;
          width: 100% !important;
          background-color: var(--colorNegro);
          border-radius: 25px;
        }
        .p-togglebutton-input {
          cursor: pointer;
          width: 100% !important;
        }

        .p-togglebutton .p-togglebutton-input {
          appearance: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          opacity: 0;
          z-index: 999;
          outline: 0 none;
          border: px solid #383838;
          border-radius: 3px;
        }
        .p-togglebutton .p-button {
          flex: 1 1 auto;
          width: 100%;
          background: var(--colorBlanco);
          border: solid var(--colorNegro) 3px;
          border-radius: 25px;
          color: var(--colorNegro);
          transition: background-color 0.2s, color 0.2s, border-color 0.2s,
            box-shadow 0.2s;
          outline-color: transparent;
        }
        .p-togglebutton .p-button .p-button-icon-left,
        .p-togglebutton .p-button .p-button-icon-right {
          padding-left: 20px;
          color: var(--colorNegro);
        }
        .p-togglebutton.p-highlight .p-button {
          background-color: var(--colorNegro);
          color: var(--colorBlanco);
          text-decoration: underline;
        }
        .p-togglebutton.p-highlight .p-button .p-button-icon-left,
        .p-togglebutton.p-highlight .p-button .p-button-icon-right {
          color: var(--colorBlanco);
        }
        .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover):not(
            .p-highlight
          )
          .p-button {
          background: var(--colorNegro);
          color: var(--colorBlanco);
        }
        .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover):not(
            .p-highlight
          )
          .p-button
          .p-button-icon-left,
        .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover):not(
            .p-highlight
          )
          .p-button
          .p-button-icon-right {
          color: var(--colorBlanco);
        }
        .p-togglebutton:not(.p-disabled):has(
            .p-togglebutton-input:hover
          ).p-highlight
          .p-button {
          background: var(--colorBlanco);
          color: var(--colorNegro);
        }
        .p-togglebutton:not(.p-disabled):has(
            .p-togglebutton-input:hover
          ).p-highlight
          .p-button
          .p-button-icon-left,
        .p-togglebutton:not(.p-disabled):has(
            .p-togglebutton-input:hover
          ).p-highlight
          .p-button
          .p-button-icon-right {
          color: var(--colorNegro);
        }
        .p-togglebutton:not(.p-disabled):has(
            .p-togglebutton-input:focus-visible
          )
          .p-button {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 1px #93cbf9;
          border-color: #64b5f6;
        }
        .p-togglebutton.p-invalid > .p-button {
          border-color: #ef9a9a;
        }

        .p-togglebutton .p-component {
          height: 3.5rem;
        }
      }

      .error {
        color: red;
      }

      .divBotonContinuar {
        height: 8rem;
        margin-top: 2rem;
        padding: var(--medida1rem);

        .almenos1boleto{
          text-align: center;
          color : red;
          padding: 0;
          margin: 0;
          font-size: 1rem;

        }

        .continuarBoton {
          height: 3.5rem;
          border: solid var(--colorNegro) 3px;
          border-radius: 25px;
          font-size: 1.5rem;
        }

        .p-button:hover {
          box-shadow: var(--colorNegro) -5px 10px 10px -5px;
          background: var(--colorNaranja);
          font-size: 1.8rem;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .mostrarInputRifa {
    .contenedorInput {
      .formulario {
        width: 85%;
      }
    }
  }
}


@media (max-width: 600px) {
  .mostrarInputRifa {
    .contenedorInput {
      .formulario {
        width: 95%;
        font-size: var(--medida1_3rem);
        .input {
          .p-inputtext {
            height: 4rem;
          }
          .p-togglebutton .p-component {
            height: 4rem;
          }
        }

        .divBotonContinuar {
          .continuarBoton {
            height: 4rem;
          }
        }
      }
    }
  }
}
