.desplazar {
    position: fixed;
    top: 0;
    padding: 0;
    right: 0;
    width: 20rem;
    z-index: 990;
    height: 100vh;
    transform: translateX(100%);
    


    .botonDesplazar {
        display: block;
        position: absolute;
        width: 70px;
        height: 70px;
        line-height: 50px;
        background-color: var(--colorNaranja);
        color: var(--colorNegro);
        text-align: left;
        top: 400px;
        left: -52px;
        z-index: -1;
        overflow: hidden;
        cursor: pointer;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        padding-left: 1rem;
        
        

        i {
            font-size: 32px;
            line-height: inherit;
            cursor: pointer;
            transform: rotate(0deg);
            
        }
    }

   
}
