.logo {
    margin: auto;
    width: var(--medida100);
    text-align: center;
    padding: 0;
    
    img {
      height: 9.5rem;
      padding:0 var(--medida1rem);
    }
  }


  @media (max-width: 500px) {

    .logo{
      img {
        height: 8.5rem;
      }
    }

}