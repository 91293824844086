.contenedorCargando{
    position: fixed;
    top: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: var(--colorNegro);
    opacity:  0.8;
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .cargando{
        
        margin: auto;

        --d:22px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        color: var(--colorBlanco);
        box-shadow: 
          calc(1*var(--d))      calc(0*var(--d))     0 0,
          calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1px,
          calc(0*var(--d))      calc(1*var(--d))     0 2px,
          calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
          calc(-1*var(--d))     calc(0*var(--d))     0 4px,
          calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
          calc(0*var(--d))      calc(-1*var(--d))    0 6px;
        animation: l27 1s infinite steps(8);
      }
      @keyframes l27 {
        100% {transform: rotate(1turn)}
      }
      
}
