.mostrarNombreRifa {
  width: var(--medida100);
  position: relative;
  top:5.4rem;

  .barraNombreRifa {
    width: var(--medida100);

    .bordesuperior {
      
      height: 8px;
      background-color: var(--colorNaranja);
      border-top: solid var(--colorNegro) 4px;
    }

    .nombreContenedorRifa {
      height: 6rem;
      background-color: var(--colorNegro);
      padding: 0;
      text-align: center;

      .rifa {
        padding: 0;
        margin: 0;
        display: block;
        color: var(--colorNaranja);
        font-size: 2rem;
      }

      .nombreRifa {
        padding: 0;
        margin: 0;
        display: block;
        color: var(--colorBlanco);
        font-size: 3rem;
      }
    }

    .bordeInferior {
      height: 8px;
      background-color: var(--colorNaranja);
      border-bottom: solid var(--colorNegro) 4px;
    }
  }
}

@media (max-width: 650px) {
  .mostrarNombreRifa {
    
    top: 8.4rem;
  }
}

@media (max-width: 500px) {
  .mostrarNombreRifa {
    position: relative;
    
  }
}


@media (max-width: 390px) {
  .mostrarNombreRifa {
    
    .barraNombreRifa {
     
        .nombreContenedorRifa {
            padding: 0.5rem;

        .rifa {
        font-size: 1.7rem;
      }

      .nombreRifa {
        font-size: 2.1rem;
      }
    }
  }
}
}
