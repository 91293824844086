.outlet {
   position: relative;
   top: 10.5rem;
   min-height: 450px;
   
}


@media (max-width: 500px) {
   .outlet {
      top: 6.7rem;

   }
 }