.mostrarRifaFoto{
    background-color: black;
    //background-image: url("../../../../public/multimedia/fotosFondo/letrasMazatlan.jpg");
      width: var(--medida100);




      .fotoAjuste{
        padding: 0;
        margin: auto;
        //border-right: solid black 5px;
        //border-left: solid black 5px;;
        width: 100%;     
        text-align: center; 

        .fotografiaRifa{
          width: 100%;
        }


      }
}

@media (max-width: 1200px) {

  .mostrarRifaFoto{
    .fotografiaRifa{
      width: 100%;
    }
      
  }
}

