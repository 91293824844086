.mostrarDescripcionRifa {
  width: var(--medida100);

  background-color: var(--colorNegro);

  .contenedorDescripcion {
    width: var(--medida70);

    .bordesuperior {
      height: 8px;
      background-color: var(--colorNaranja);
      border-top: solid var(--colorNegro) 4px;
    }

    .rifaDescripcion {
      margin: auto;
      width: 60%;
      padding: 1rem;
      text-align: center;

      .subtitulo {
        font-size: 2rem;
        color: var(--colorNaranja);
        
      }

      .detalle {
        color: var(--colorBlanco);
        font-size: 2.3rem;
        text-align: center;
        width: 100%;
        padding-top: .5rem;
      }
    }

    .bordeInferior {
      height: 8px;
      background-color: var(--colorNaranja);
      border-bottom: solid var(--colorNegro) 4px;
    }
  }
}

@media (max-width: 1000px) {
  .mostrarDescripcionRifa {
    .contenedorDescripcion {
      .rifaDescripcion {
        width: 80%;

        
      }
    }
  }
}


@media (max-width: 650px) {
  .mostrarDescripcionRifa {
    .contenedorDescripcion {
      .rifaDescripcion {
        .subtitulo {
          font-size: 1.6rem;
          
          
        }
  
        .detalle {
          color: var(--colorBlanco);
          font-size: 1.8rem;
        }

        
      }
    }
  }

}


@media (max-width: 500px) {
  .mostrarDescripcionRifa {
    .contenedorDescripcion {
      .rifaDescripcion {
        padding: 0.5rem;
        width: 90%;


      }
    }
  }
}


@media (max-width: 404px) {
  .mostrarDescripcionRifa {
    .contenedorDescripcion {
      .rifaDescripcion {
        .subtitulo {
          font-size: 1.4rem;
          
          
        }
  
        .detalle {
          color: var(--colorBlanco);
          font-size: 1.6rem;
        }

      }
    }
  }
}