.ContenedorTerminos {
    width: var(--medida100);
    background-color: var(--colorNegro);
    background-image: url("../../../public/multimedia/fotosFondo/letrasMazatlanNegro.jpg");
    padding: 1rem 0;

  
    .Terminos {
      margin: auto;
      padding: 1rem;
      width: 60%;
      background-color: var(--colorBlanco);
      border-radius: 50px;
      color: var(--colorNegro);
      font-size: var(--medida1_5rem);
      text-align: justify;
      
      .titulo{
        text-align: center;
      }
  
    }
  }
  
  @media (max-width: 951px) {
    .ContenedorTerminos {
      .Terminos {
        width: 90%;
      }
    }
  }
  