.contenedorBarra{    position: sticky;
    top: 10.5rem;
    z-index: 100;

    .barraCantidad{
        display: inline-flex;
        width: 100%;
        padding: 0 1rem;
        background-color: var(--colorNegro);
        border-bottom: solid var(--colorNaranja) 4px;
        margin: 0;
        height: 3.5rem;
        justify-content: space-between;
        align-items: center;

    

    .texto{
        display: block;
        font-size: 2.3rem;
        color: var(--colorBlanco);
    }
    

}
}
@media (max-width: 650px) {
    .contenedorBarra{
    .barraCantidad{
        height: 6rem;
        display: block;
        justify-content: center;
        align-content: center;

        .texto{
            text-align: center;
            font-size: 1.9rem;
            color: var(--colorBlanco);
            

        }
    
    }

}
    }
@media (max-width: 500px) {

    .contenedorBarra{
        top: 9.1rem;
    
    }

}

@media (max-width: 404px) {
    .contenedorBarra{
    .barraCantidad{
        display:block;
        padding: 0.3rem;
       

        .texto{
        
            text-align: center;
            font-size: 2rem;
            color: var(--colorBlanco);
        }
    
    }

}
}