.contenedorMercadoPago {
  position: fixed;
  top: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: var(--colorNegro);
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;

  .contenedor {
    width: 100%;
    font-size: var(--medida1_8rem);
    text-align: left;
    margin: var(--auto);
    padding: 0rem 1rem;

    .contenido {
      width: 90%;
      padding-bottom: 2rem;
      margin: auto;
      margin-top: 1rem;
      background-color: var(--colorBlanco);
      border-radius: 50px;
      color: var(--colorNegro);

      .textos{
        border-bottom: dashed var(--medida2px) var(--colorNegro);
        padding: 0.5rem 0rem;
        width: 100%;
        height: fit-content;
        justify-content: center;
        text-align: center;
        

        .texto {
          font-size: 2rem;
        }

        .textoResaltado{
          font-size: 2rem;
          font-weight: bold;
          padding: 0;
          
          margin-top: -2rem;
        }

        .textoInformativo { 
          font-size: 1.8rem;
          text-align: center;
        }

        .textoEsperaMercadoPago { 
          font-size: 1.2rem;
          text-align: center;
        }
      }

      .detalles {
        border-bottom: dashed var(--medida2px) var(--colorNegro);
        padding: 0.5rem 0rem;
        width: 100%;
        height: fit-content;
        justify-content: center;
        text-align: center;

        
        .botonCancelar {
          margin: auto;
          width: 50%;
          height: 3rem;
          background-color: darkred;
          font-size: 1.5rem;
          color: white;
        }

        .mercadoPago{
          width: 90%;
          margin: auto;
        }

      }
    }
  }

  
}


@media (max-width: 375px) {
  .contenedorMercadoPago {
    .contenedor {
      padding: 0 2px;
      .contenido {
        width: 100% ;
        .detalles{
          width: 100%;
        }
        .mercadoPago{
          width: 100%;

        }
      
      }
    }
  }
}