.ContenedorInstrucciones {
    width: var(--medida100);
    background-color: var(--colorNegro);
    background-image: url("../../../public/multimedia/fotosFondo/letrasMazatlanNegro.jpg");
    padding: 1rem;
    text-align: center;
  
    .instrucciones{
      display: var(--Flex);
      
      .imagenInstruccion{
        margin: 1rem;
        width: 600px;
        text-align: center;
        border: solid 2px white;
        
      }
  
    }
  
  }

  @media (max-width: 600px) {
    .ContenedorInstrucciones {
      .instrucciones {
        .imagenInstruccion {
          width: 100%;
          height: max-content;
          margin: auto;
          margin-top: .5rem;
        }
      }
    }
  }
  