.barraPrincipal{
    height: 10.5rem;
    position: fixed;
    display: flex;
    align-items: center;
    background-color: var(--colorBlanco);
    justify-content: space-between;
    padding: 0 1rem;
    margin: 0;
    width: 100%;
    z-index: 995;
    border-bottom: solid var(--colorNaranja) 2px;
}

@media (max-width: 500px) {
    .barraPrincipal {
        height: 9.1rem;
      padding: 0;
    }
  }